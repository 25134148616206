import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
//import Confetti from 'react-confetti';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/WordWheel/Player";
import Tutorial from "components/WordWheel/WordTutorial";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/WordWheel/WordHostStyles.module.scss';

import logo from "images/Host/word-wheel-shop-logo.png";
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import RaysImg from "images/WordWheel/rays.png";
import Round1Img from "images/WordWheel/round-1-text.png";
import Round2Img from "images/WordWheel/round-2-text.png";
import Round3Img from "images/WordWheel/round-3-text.png";
import WinnerBanner from "images/WordWheel/winner-banner.png";
import WinnersTitle from "images/WordWheel/winners-banner.png";
import ClockImg from "images/WordWheel/clock.png";
import ClockHandImg from "images/WordWheel/clock-hand.png";
import ClockBg from "images/WordWheel/clock-bg.png";
import WheelBg from "images/WordWheel/wheel.png";
import Star from "images/WordWheel/star.png";

import ClickSFX from "audio/click.wav";
import ClockTickSFX from "audio/clock-ticking.mp3";
import CorrectSFX from "audio/correct.wav";
import ItemPopupSFX from "audio/item-popup.wav";
import RoundOverSFX from "audio/round-over.wav";
import SkipSFX from "audio/skip.wav";
import BGMusic from "audio/temp-bg-music.wav";
import Woosh from "audio/woosh.mp3";
import FanfareSFX from "audio/fanfare.wav";
//import RevealSFX from "audio/reveal.wav";
//import DrumRollSFX from "audio/drum-roll.wav";

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: BGMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    Click: {
        import: ClickSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ClockTick: {
        import: ClockTickSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    Correct: {
        import: CorrectSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ItemPopup: {
        import: ItemPopupSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    RoundOver: {
        import: RoundOverSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Skip: {
        import: SkipSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Woosh: {
        import: Woosh,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Fanfare: {
        import: FanfareSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
};


const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Playing: "playing",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "word_wheel";


export class WordHost extends Component {
    static displayName = WordHost.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,
            reconnectionToken: "",
            logStreamId: "",

            players: [],
            //            players: [
            //                {
            //                    name: "Player1",
            //                    id: "1",
            //                    avatar: 1,
            //                    wordData: {
            //                        answer: "answer",
            //                        score: 1,
            //                        ready: false,
            //                        showAnswer: false,
            //                        showValidation: false,
            //                        isCorrect: false,
            //                        answerHistory: []
            //                    },
            //                    showPlayer: false,
            //                },
            //                {
            //                    name: "Player2",
            //                    id: "2",
            //                    avatar: 2,
            //                    wordData: {
            //                        answer: "answer",
            //                        score: 1,
            //                        ready: false,
            //                        showAnswer: false,
            //                        showValidation: false,
            //                        isCorrect: false,
            //                        answerHistory: []
            //                    },
            //                    showPlayer: false,
            //                },
            //                {
            //                    name: "Player3",
            //                    id: "3",
            //                    avatar: 3,
            //                    wordData: {
            //                        answer: "answer",
            //                        score:2,
            //                        ready: false,
            //                        showAnswer: false,
            //                        showValidation: false,
            //                        isCorrect: false,
            //                        answerHistory: []
            //                    },
            //                    showPlayer: false,
            //                },
            //                {
            //                    name: "Player4",
            //                    id: "4",
            //                    avatar: 4,
            //                    wordData: {
            //                        answer: "answer",
            //                        score: 2,
            //                        ready: false,
            //                        showAnswer: false,
            //                        showValidation: false,
            //                        isCorrect: false,
            //                        answerHistory: [
            //"Interest"                        ]
            //                    },
            //                    showPlayer: false,
            //                },
            //                {
            //                    "name": "Player5",
            //                    "id": "5",
            //                    "avatar": 5,
            //wordData: {
            //                        answer: "answer",
            //                        score: 1,
            //                        ready: false,
            //                        showAnswer: false,
            //                        showValidation: false,
            //    isCorrect: false,
            //    answerHistory: [
            //"Chocolate"    ]
            //                    },
            //                    showPlayer: false,
            //                },
            //                {
            //                    "name": "WWWWWWWWWW",
            //                    "id": "6",
            //                    "avatar": 6,
            //wordData: {
            //                        answer: "answer",
            //                        score: 2,
            //                        ready: false,
            //                        showAnswer: false,
            //                        showValidation: false,
            //    isCorrect: false,
            //    answerHistory: [
            //        "supermans"     ]
            //                    },
            //                    showPlayer: false,
            //                },
            //                {
            //                    "name": "Pla",
            //                    "id": "7",
            //                    "avatar": 7,
            //wordData: {
            //                        answer: "answer",
            //                        score: 2,
            //                        ready: false,
            //                        showAnswer: false,
            //                        showValidation: false,
            //    isCorrect: false,
            //    answerHistory: [
            //"banana"    ]
            //                    },
            //                    showPlayer: false,
            //                },
            //            ],
            playersRequired: 2,
            showPlayers: true,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,
            //showTutorial: true,
            skipTutorialCount: 0,

            timerText: 0,
            showTimer: false,
            //showTimer: true,

            showStartButtons: true,
            //showStartButtons: false,
            showPlayAgainButtons: false,
            //showPlayAgainButtons: true,

            roundNumber: 0,
            currentWordShuffled: "",

            showRoundNumber: false,
            //showRoundNumber: true,
            showRoundNumberRays: false,

            showInfoBox: false,
            //showInfoBox: true,
            showWheelSection: false,
            //showWheelSection: true,
            showWheelRays: false,


            showWinnerTitle: false,
            showWinnerRays: false,
            showWinners: {},

            showEndGame: false,
            //showEndGame: true,

            //doConfetti: false,

            missedWords: [],
            commonWords: [],

            showRoundEndSectionTitles: false,
            //showRoundEndSection: true,
            showCommonWords: [],
            showMissedWords: [],
        };
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.setTags();
        this.doReconnect();

        //this.toggleMute(true, false);
        this.initAudio();
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.BgMusic, true, 0.15);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio && audio.loaded) audio.loaded.play();
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            setTimeout(() => { // animate in timeout
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };
                statePlayer.showPlayer = true;
                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            }, 500);
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = () => {
        this.playAudio(audio.Click);
        this.setState({ showStartButtons: false });
        //this.state.room.send("begin_game", { skipTutorial: true, });
        this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
    }
    goToLobby = () => {
        this.playAudio(audio.Click);
        this.state.room.send("change_game", {});
    }
    signalNewGame = () => {
        this.playAudio(audio.Click);
        this.setState({ showPlayAgainButtons: false, });
        setTimeout(() => {
            this.state.room.send("word_new_game", {});
        }, 1000);
    }



    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    hideTimer() {
        this.setState({ showTimer: false, });

        //if (audio.Timer5SecondsClock.loaded) audio.Timer5SecondsClock.loaded.stop();
    }

    getRoundCountImg = () => {
        const roundImgs = [Round1Img, Round2Img, Round3Img];
        return roundImgs[this.state.roundNumber - 1];
    }

    shuffle(string) {
        return string.split('')
            .sort(() => 0.5 - Math.random())
            .join('');
    }

    async doStartRound() {
        this.setState({ showPlayers: true, });
        await this.sleep(750);
        this.setState({ showRoundNumberRays: true });
        await this.sleep(500);
        this.playAudio(audio.Woosh);
        this.setState({ showRoundNumber: true, });
        await this.sleep(5000);
        this.playAudio(audio.Woosh);
        this.setState({ showRoundNumber: false, });
        await this.sleep(500);
        this.setState({ showRoundNumberRays: false, });
        await this.sleep(2000);
        this.playAudio(audio.ItemPopup);
        this.setState({ showInfoBox: true, });
        await this.sleep(2000);
        this.setState({ showWheelRays: true });
        await this.sleep(250);
        this.playAudio(audio.ItemPopup);
        this.setState({ showWheelSection: true, });
        await this.sleep(2000);
        this.state.room.send("round_started", {});
    }

    async doRoundEnd(message) {

        this.setState({ showWheelSection: false, missedWords: message.missedWords, commonWords: message.commonWords });
        await this.sleep(250);
        this.setState({ showInfoBox: false, });
        await this.sleep(250);
        this.setState({ showWheelRays: false });
        await this.sleep(2000);

        if (message.commonWords.length === 0 && message.missedWords.length === 0) {
            this.state.room.send("start_round", {});
        } else {
            this.setState({ showRoundEndSectionTitles: true });
            this.playAudio(audio.ItemPopup);
            await this.sleep(1000);
            for (let i = 0; i < message.commonWords.length; i++) {
                let showCommonWords = [...this.state.showCommonWords];
                showCommonWords[i] = true;
                this.setState({ showCommonWords });
                this.playAudio(audio.ItemPopup);
                await this.sleep(250);
            }
            for (let i = 0; i < message.missedWords.length; i++) {
                let showMissedWords = [...this.state.showMissedWords];
                showMissedWords[i] = true;
                this.setState({ showMissedWords });
                this.playAudio(audio.ItemPopup);
                await this.sleep(250);
            }
            await this.sleep(4000);
            this.setState({ showRoundEndSectionTitles: false, showMissedWords: [], showCommonWords: [] });
            this.state.room.send("start_round", {});
        }
    }

    async doGameOver() {
        await this.sleep(2000);
        this.setState({ showWinnerRays: true, showWinnerTitle: true, showPlayers: false, });
        await this.sleep(1000);
        let winners = this.getWinners();
        let showWinners = {};
        winners.forEach((x, i) => {
            showWinners[x.id] = true;
        });
        this.playAudio(audio.Fanfare);
        this.setState({ showWinners });
        await this.sleep(5000);
        this.setState({ showWinners: {}, showWinnerRays: false, showWinnerTitle: false, });
        await this.sleep(2000);
        this.state.room.send("reached_end", {});
        this.setState({ showPlayAgainButtons: true, });
    }

    getWinners() {
        let statePlayers = [...this.state.players];
        let winners = [];
        let highestScore = 0;
        for (let i = 0; i < statePlayers.length; i++) {
            if (statePlayers[i].wordData.score > highestScore) {
                highestScore = statePlayers[i].wordData.score;
            }
        }

        for (let i = 0; i < statePlayers.length; i++) {
            if (statePlayers[i].wordData.score == highestScore) {
                winners.push(statePlayers[i]);
            }
        }

        return winners;
    }

    getSortedPlayersByScore() {
        let statePlayers = [...this.state.players];
        statePlayers.sort((a, b) => {
            return b.wordData.score - a.wordData.score;
        });
        let lastIndex = 1;
        return <React.Fragment>
            {
                statePlayers.map((x, index, arr) => {
                    if (index > 0 && x.wordData.score < arr[index - 1].wordData.score) lastIndex++;
                    return <tr className={`${styles.tableRow} ${styles.player}`}>
                        <div className={styles.position}>{lastIndex}</div>
                        <div className={styles.name}>{x.name}</div>
                        <div className={styles.score}>{x.wordData.score}<span className={styles.smaller}>pts</span></div>
                        <div className={styles.potato}>
                            <Lottie
                                options={getAvatarById(x.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    </tr>
                })
            }
        </React.Fragment>
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }


    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const token = this.getQueryStringValue("token");

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });
                        this.setState({ roomState: state, logStreamId: state.uniqueId, });
                        LoggingService.streamLog(state.uniqueId, `Host Reconnected to Word Wheel, Reconnection Token: ${room.reconnectionToken}`);

                        // this casues everyone to go back to lobby if the host has refreshed their page mid game
                        if (state.wordData.gameState == GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, });
                    });

                    room.state.players.onAdd((player, key) => {
                        console.log(player, "has been added at", key);
                        this.checkAndAddPlayer(player);

                        const changes = ["connected", "votedSkip"];
                        changes.forEach(change => {
                            player.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };

                                statePlayer[change] = value;

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });

                        const wordPlayerChanges = ["score",];
                        wordPlayerChanges.forEach(change => {
                            player.wordData.listen(change, (value) => {
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };

                                statePlayer.wordData[change] = value;

                                if (change === "score" && value > 0) {
                                    this.playAudio(audio.Correct);
                                }

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });

                        const wordPlayerArrayChanges = ["wordHistory",];
                        wordPlayerArrayChanges.forEach(arrayChange => {
                            player.wordData[arrayChange].onChange((change, index) => {
                                console.log("change ", change, "index", index);
                                let statePlayers = [...this.state.players];
                                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                                let statePlayer = { ...statePlayers[pos] };
                                statePlayer.wordData[arrayChange][index] = change;

                                statePlayers[pos] = statePlayer;
                                this.setState({ players: statePlayers });
                            });
                        });
                    });
                    room.state.players.onRemove((player, key) => {
                        console.log(player, "has been removed at", key);
                        this.removePlayer(player.id);
                    });

                    const wordWheelStateVars = ["gameState", "roundNumber", "currentWordId", "currentWordShuffled"];
                    wordWheelStateVars.forEach((stateVar) => {
                        room.state.wordData.listen(stateVar, (change) => {
                            let update = {};
                            update[stateVar] = change;
                            this.setState(update);
                        });
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, });
                        this.state.room.send("show_tutorial", {});
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, });
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.gameState);
                        if ((this.state.gameState === GameStates.Loading || this.state.gameState === GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
                            room.send("start_round", {});
                        }
                    });

                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        this.doStartRound();
                    });

                    room.onMessage("show_round_results", (message) => {
                        console.log("show_round_results", "received on", room.name, message);
                        this.doRoundEnd(message);
                    });

                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.doGameOver();
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}/lobby/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.setState({ showPlayAgainButtons: false, });
                        this.state.room.send("start_round", {});
                    });

                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);

                        this.setState({ showTimer: true, });
                        if (message.count <= 5) {
                            this.playAudio(audio.ClockTick);
                            if (message.count == 5) {
                                //this.playAudio(audio.Timer5SecondsStart);
                                this.playAudio(audio.Timer5SecondsClock);
                            }
                            if (message.count <= 0) {
                                this.playAudio(audio.RoundOver);
                                this.hideTimer();
                            }
                        }
                        this.setState({ timerText: message.count, });
                    });

                    room.onError((code, message) => {
                        Sentry.captureMessage(`WS Received: onError; message: ${message}`);
                        LoggingService.streamLog(this.state.logStreamId, `Host OnError at Word Wheel, code: ${code} Message: ${JSON.stringify(message)}`);
                        console.log(this.client.id, "couldn't join", room.name);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host Left Word Wheel, Code: ${code}`);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Word Wheel.";
                    if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Host OnJoinError at Word Wheel: ${JSON.stringify(e)}`);
                    //LoggingService.logError(message, e);
                    if (!message.includes("has been disposed")) Sentry.captureMessage(`doReconnect Error: ${message}`);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}/` });
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={styles.gameContainer}>
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} />
                            </div>
                            :
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showTutorial &&
                                    <Tutorial room={this.state.room} players={this.state.players} />
                                }
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.connected ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <div className={styles.startButtonSection}>
                                            <button className={`${styles.mainButton} ${styles.alt}`} onClick={this.signalStartGame}>
                                            Start Game
                                        </button>
                                        <button className={`${styles.mainButton} ${styles.smaller}`} onClick={this.goToLobby}>
                                            Go To Lobby
                                        </button>
                                        <div className={styles.skipBox}>
                                            <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                            <label for="checkbox">Skip Tutorial</label>
                                        </div>
                                    </div>
                                }
                                <div className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 == 0) {
                                                return <Player player={x} left={true} room={this.state.room} />
                                            }
                                        })
                                    }
                                </div>
                                <div className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers && styles.show}`}>
                                    {
                                        this.state.players.map((x, index) => {
                                            if (index % 2 == 1) {
                                                return <Player player={x} room={this.state.room} />
                                            }
                                        })
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.playAgainButtonSection}>
                                        <button className={`${styles.button} ${styles.alt}`} onClick={this.signalNewGame}>
                                            Play Again
                                        </button>
                                        <button className={`${styles.button}`} onClick={this.goToLobby}>
                                            Return to lobby
                                        </button>
                                    </div>
                                }
                                <div className={`${styles.roundNumberSection} `}>
                                    <div className={`${styles.raysContainer} ${this.state.showRoundNumberRays && styles.show}`}>
                                        <img src={RaysImg} className={`${styles.rays} `} alt={"round-number-rays"} />
                                    </div>
                                    <div className={`${styles.roundTextContainer} ${this.state.showRoundNumber && styles.show}`}>
                                        <div className={`${styles.roundText}`}>
                                            <p className={`${styles.gradiant}`}>ROUND</p>
                                            <div className={styles.circle}>
                                                <div className={styles.innerCircle}>
                                                    <p className={styles.number} >{this.state.roundNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.wheelSection} `}>
                                    <div className={`${styles.raysContainer} ${this.state.showWheelRays && styles.show}`}>
                                        <img src={RaysImg} className={styles.rays} />
                                    </div>
                                    <div className={`${styles.infoBox} ${this.state.showInfoBox && styles.show}`}>
                                        <div className={styles.background} />
                                        <div className={styles.innerText}>Find as many words as you can<br /> using the middle letter!</div>
                                    </div>
                                    <div className={`${styles.wheel} ${this.state.showWheelSection && styles.show}`} >
                                        <img className={styles.wheelBg} src={WheelBg} />
                                        <div className={styles.wheelContainer}>
                                            {
                                                this.state.currentWordShuffled.split("", this.state.currentWordShuffled.length).map((x, index) => {
                                                    if (index === this.state.currentWordShuffled.length - 1) {
                                                        return <div className={styles.centerCircle}>{x}</div>
                                                    } else {
                                                        return <div className={styles.segment}>
                                                            <div className={styles.segmentText}>{x}</div>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    {/*<div className={styles.bar}>*/}
                                    {/*    <img className={styles.barFront} src={BarFront} />*/}
                                    {/*    <div className={styles.barFillContainer}>*/}
                                    {/*        <div className={styles.fill }></div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className={`${styles.roundEndSection} `}>
                                    {
                                        this.state.commonWords.length > 0 &&
                                        <div className={styles.wordList}>
                                            <div className={`${styles.wordListTitle} ${this.state.showRoundEndSectionTitles && styles.show}`}>Common Words</div>
                                            {
                                                this.state.commonWords.map((x, index) => {
                                                    return <div className={`${styles.word} ${this.state.showCommonWords[index] && styles.show}`}>
                                                        <div className={styles.star}>
                                                            <img src={Star} alt={`${x.word}-star-${x.count}`} />
                                                            <div className={styles.count}>{x.count}</div>
                                                        </div>
                                                        {x.word}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.missedWords.length > 0 &&
                                        <div className={styles.wordList}>
                                            <div className={`${styles.wordListTitle} ${this.state.showRoundEndSectionTitles && styles.show}`}>Missed Words</div>
                                            {
                                                this.state.missedWords.map((x, index) => {
                                                    return <div className={`${styles.word} ${this.state.showMissedWords[index] && styles.show}`}>{x}</div>
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                                <div className={`${styles.winnerSection} `}>
                                    <img src={WinnersTitle} className={`${styles.winnersTitle} ${this.state.showWinnerTitle && styles.show}`} />
                                    <div className={`${styles.raysContainer} ${this.state.showWinnerRays && styles.show}`}>
                                        <img src={RaysImg} className={styles.rays} />
                                    </div>
                                    <div className={styles.winnerList}>
                                        {
                                            this.getWinners().map((x, index, arr) => {
                                                let size = [1, 2].includes(arr.length) ? "large" : [3, 4].includes(arr.length) ? "medium" : "small";
                                                return <div className={`${styles.winner} ${this.state.showWinners[x.id] && styles.show} ${size == "large" ? styles.large : size == "medium" ? styles.medium : styles.small }`}>
                                                    <div className={`${styles.potato}`}>
                                                        <div className={`${styles.nameBox}`}>
                                                            <img className={styles.winnerOverlay} src={WinnerBanner} />
                                                            <div className={`${styles.name}`}>
                                                                {x.name}
                                                            </div>
                                                        </div>
                                                        <Lottie
                                                            options={getAvatarById(x.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.gameOverSection}`}>
                                    <div className={`${styles.leaderboardSection} ${this.state.showPlayAgainButtons && styles.show}`}>
                                        <div className={`${styles.tableRow} ${styles.header}`}>
                                            <div className={styles.position}>position</div>
                                            <div className={styles.name}>player</div>
                                            <div className={styles.score}>score</div>
                                        </div>
                                        {
                                            this.getSortedPlayersByScore()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.timerSection} ${this.state.showTimer && styles.show}`}>
                                    <img src={ClockBg} className={styles.clockBg} />
                                    <div className={styles.clock}>
                                        <img src={ClockImg} className={styles.clockImg} />
                                        <img src={ClockHandImg} className={styles.clockHand} />
                                    </div>
                                    <div className={styles.timerText}>{this.state.timerText}</div>
                                </div>

                                {/*<div style={{ position: "absolute", zIndex:1000 }}>*/}
                                {/*    */}{/*<button onClick={() => this.setState({ showRoundEndSection: !this.state.showRoundEndSection })}>showRoundEndSection</button>*/}
                                {/*    <button onClick={() => this.setState({ showTutorial: true, })}>showTutorial</button>*/}
                                {/*</div>*/}
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}