import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const logError = (message, error) => {
    var configLogError = {
        method: 'post',
        url: API_URL + 'api/logging/log-error',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            message,
            error
        }
    };

    return instance(configLogError)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error.response.data;
        });
};

const streamLog = (uniqueId, message) => {
    var configStreamLog = {
        method: 'post',
        url: API_URL + 'api/logging/streamLog',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            uniqueId,
            message,
        }
    };

    return instance(configStreamLog)
        .then(function (response) {
            console.log("Stream Log success");
        }).catch(function (error) {
            console.log("Stream log failed: ", error);
            return error;
        });
}

export default {
    logError,
    streamLog,
};
